import React from 'react'
import Content from '../components/content'
import InfoList from '../components/info-list'
import titleImg from '../data/general-information/bg6.jpg'

const GeneralInfo = () => (
  <Content titleImg={titleImg} title='General Information'>
    <InfoList/>
  </Content>
)

export default GeneralInfo